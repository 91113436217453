import React, { Component } from "react";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            href="#navbar"
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          ></nav>
          <aside id="colorlib-aside">
            <div className="text-center">
              <div
                className="author-img"
                style={{ backgroundImage: "url(images/about.png)" }}
              />
              <h2 id="colorlib-logo">
                <a href="/">Austin Charcuterie Shop</a>
              </h2>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active">
                    <a href="#home" data-nav-section="home">
                      Introduction
                    </a>
                  </li>

                  <li>
                    <a
                      href="#gallery"
                      data-nav-section="gallery"
                      rel="nofollow"
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      href="#pricing"
                      data-nav-section="pricing"
                      rel="nofollow"
                    >
                      Pricing
                    </a>
                  </li>

                  <li>
                    <a href="#form" data-nav-section="form" rel="nofollow">
                      Order Form
                    </a>
                  </li>
                  <li>
                    <a href="#about" data-nav-section="about">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/austincharcuterieshop">
                      Facebook
                      {/* <i className="icon-facebook2" /> */}
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/austincharcuterieshop/">
                      Instagram
                      <i className="icon-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <li>
                  <p>(337) 214-3347</p>
                </li>
                <span className="email">
                  <i className="icon-mail"></i> austincharcuterie@gmail.com
                </span>
              </ul>
            </nav>
          </aside>
        </div>
      </div>
    );
  }
}
