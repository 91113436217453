import React, { Component } from "react";
import "./App.css";
import Sidebar from "./components/sidebar";
import Introduction from "./components/introduction";
import About from "./components/about";
import Form from "./components/form";
import Pricing from "./components/pricing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Gallery from "./components/gallery";
import ReactGA from "react-ga";

class App extends Component {
  render() {
    return (
      <Router>
        <div id="colorlib-page">
          <div id="container-wrap">
            <Sidebar></Sidebar>
            <Routes>
              <Route exact path="/" element={<Home />} />
            </Routes>
          </div>
        </div>
      </Router>
    );
  }
}
function Home() {
  ReactGA.initialize("UA-246555618-1");

  return (
    <div id="colorlib-main">
      <Introduction></Introduction>
      <Gallery></Gallery>
      <Pricing></Pricing>
      <Form></Form>
      <About></About>
    </div>
  );
}
export default App;
