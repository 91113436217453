import React, { Component } from "react";
export default class Form extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="form">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 "
                data-animate-effect="fadeInLeft"
              >
                <h2 className="colorlib-heading ">Order from Us</h2>
                <span>
                  <h3>
                    {" "}
                    This is our regular menu order form for easy ordering. We
                    have fruits, cheese, meats, and veggies suitable for any
                    event and dietary preference.
                  </h3>
                </span>
                <p>
                  To place an order, please submit this form or contact via
                  Instagram, Facebook, Email, or Phone.
                </p>
                <a
                  itemprop="url"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfwb86q9fEfk379IvElZxYiep95fJLxfR_DM7e7h_jZWLkGYQ/viewform?embedded=true"
                >
                  Click here to enter your order!
                </a>
              </div>
            </div>
          </div>

          <div class="container">
            <iframe
              class="responsive-iframe"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfwb86q9fEfk379IvElZxYiep95fJLxfR_DM7e7h_jZWLkGYQ/viewform?embedded=true"
              height="1000px"
              marginWidth="0"
              frameBorder="0"
            >
              Loading…
            </iframe>
          </div>
        </section>
      </div>
    );
  }
}
