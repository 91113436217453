import React, { Component } from "react";
import ReactGA from "react-ga";

export default class About extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm "
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">
                        <h2>About Austin Charcuterie Shop</h2>
                      </span>
                      <p className="display-linebreak">
                        Austin Charcuterie Shop is an Austin-based food company
                        that specializes in beautifully crafted charcuterie and
                        cheese boards delivered to wherever they are wanted. Our
                        boards come fresh and ready to enjoy. I am Ruby, a chef
                        at Four Seasons and the creator of these beautiful
                        trays. I come from humble beginnings. I began my career
                        in the food industry working at McDonald's. As my career
                        progressed, I learned that charcuterie was a passion of
                        mine. I want to share the beauty of this art with y'all.
                        It’s been an exciting journey to fulfill this passion of
                        mine! I wanted an opportunity to create a business and
                        work for myself. To me, family and love expands across
                        various spectrums. I look up to our small businesses to
                        continuously learn more and support local efforts to
                        positively contribute to our economy. I also love the
                        passion and inspiration behind the innovative ideas we
                        get to express as business owners.
                      </p>
                      <div
                        className="col-md-4 "
                        data-animate-effect="fadeInLeft"
                      >
                        <div>
                          <img
                            src={process.env.PUBLIC_URL + "/images/whoami.png"}
                            alt="The owner of Austin Charcuterie Shop"
                            width="360px"
                            height="480px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row row-pt-md">
              <div className="col-md-4 text-center ">
                <div className="services color-1">
                  <div
                    className="project"
                    style={{ backgroundImage: "url(images/watermelon.jpg)" }}
                  >
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center ">
                <div className="services color-3">
                  <div
                    className="project"
                    style={{ backgroundImage: "url(images/cheese.jpg)" }}
                  >
                    {" "}
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center ">
                <div className="services color-5">
                  <div
                    className="project"
                    style={{ backgroundImage: "url(images/flowers.jpg)" }}
                  >
                    {" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}
