import React, { Component } from "react";

export default class Pricing extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="pricing">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 "
                data-animate-effect="fadeInLeft"
              >
                <h2 className="colorlib-heading ">Pricing</h2>
                <ul>
                  <li>
                    We require one week's notice to prep orders for over 4
                    people.
                  </li>
                  <li>We accept cash or Venmo.</li>
                  <li>
                    Free delivery for orders over $50 and 5 miles from central
                    Austin. Any orders below will incur a delivery fee depending
                    on location. Pickup is free.{" "}
                  </li>
                  <li>
                    Please feel free to reach out to us if you have any
                    questions or want to place an order. Thank you!
                  </li>
                </ul>
                <div>
                  <h2 className="colorlib-heading ">SERVING TIPS </h2>

                  <p>
                    Our cheese boards are great at room temperature. Cheese
                    reaches optimal flavor and texture at room temperature.
                    Remove from your refrigerator 45 minutes to 1 hour before
                    serving. You can leave the charcuterie boards in your
                    refrigerator and take out 20 minutes before serving. If
                    you'll enjoy your board the next day or two, we recommend
                    leaving the board wrapped to preserve the freshness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
